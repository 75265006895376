import React,{useState ,Fragment ,useReducer, useContext, useEffect} from "react";
import { Link ,useHistory, useLocation} from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Image
import profile_h from "../../../images/user.jpg";
import avatar from "../../../images/logo.png";
import LogoutPage from './Logout';
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";
import { ThemeContext } from "../../../context/ThemeContext";
import { GlobalSet } from '../../components/global/GlobalProvider';
import { Row, Card, Col, Button, Modal, Container, Dropdown ,Badge } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";

import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Header = ({ onNote }) => {
  const { CurrencySymbol } = GlobalSet();
  const { BaseUrl } = GlobalSet();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const auth_token = localStorage.getItem('auth_token');
  // Check if ?login is in the URL, and open the modal
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("login")) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [location]);

  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  // Close the modal and remove ?login from the URL
  const handleClose = () => {
    setShow(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("login");
    history.replace({
      search: searchParams.toString(),
    });
  };

  // Open the modal by adding ?login to the URL
  const handleShowLogin = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("login", "true");
    history.replace({
      search: searchParams.toString(),
    });
  };


    const [loginInput, setLogin] = useState({
        username: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        
        const data = {
            username: loginInput.username,
            password: loginInput.password,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
            axios.post(`/apps-user-login`, data).then(res => {
                if(res.data.status === 'success')
                {
                    localStorage.setItem('auth_token', res.data.accessToken);
                    //swal("Success",res.data.message,"success");
                    //history.push('/');
                    swal("Success",res.data.message,"success");
                  window.location.href = '/'; 
 
                }
                else if(res.data.status === 'fail')
                {

                    swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setLogin({...loginInput, error_list: res.data.validation_error});
                }
            });
          }








  const [showModal, setShowModal] = useState(false); // For modal visibility


  const toggleModal = () => {
    setShowModal(!showModal); // Toggle modal visibility
  };






  const handleLanClick = (divLang) => {
    localStorage.setItem('selectedLanguage', divLang);
    window.location.reload();
  };

  let  divLang;
  const sel_Language = localStorage.getItem('selectedLanguage');
  if (sel_Language !== null) {
   divLang = sel_Language;
  } else {
   divLang = "en";
  }
  const [selectedLanguage, setSelectedLanguage] = useState(divLang);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };


  const handleCarClick = (divCarr) => {
    localStorage.setItem('selectedCurrency', divCarr);
      let  cur_icon;
    if (divCarr === "USD") {
     cur_icon = "$";
    } else if (divCarr === "EUR"){
     cur_icon = "€";
    } else if (divCarr === "BDT"){
     cur_icon = "৳";
    } else if (divCarr === "INR"){
     cur_icon = "₹";
    }
    localStorage.setItem('CurrencySymbol', cur_icon);
    window.location.reload();
  };
  let  divCarr;
  const sel_cudrrency = localStorage.getItem('selectedCurrency');
  if (sel_cudrrency !== null) {
   divCarr = sel_cudrrency;
  } else {
   divCarr = "USD";
  }
  const [selectedCurrency, setSelectedCurrency] = useState(divCarr);
  
  const [settingToggle, setSettingToggle] = useState(false);
  const [demoToggle, setDemoToggle] = useState(false);

   const [user, setUser] = useState(null);
   const [balance, setBalance] = useState(0); // Separate balance state
   const fetchProfileData = () => {
      const data = {
        key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        currency: selectedCurrency,
      };
      if (auth_token !== null) {
        axios.post('/apps-profile', data)
          .then(response => {
            setUser(response.data);
            setBalance(response.data?.data?.balance || 0); // Update balance
          })
          .catch(error => {
            console.log(error);
          });
      }
    };
    useEffect(() => {
      fetchProfileData();
      const intervalId = setInterval(fetchProfileData, 5000);
      return () => clearInterval(intervalId);
    }, []);


var AuthButtons = '';
if(!localStorage.getItem('auth_token'))
{
  AuthButtons = (
  <ul className="navbar-nav header-right">

      <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
              <Modal.Title>Login Here</Modal.Title>
          </Modal.Header>
           <Modal.Body>
              <form onSubmit={loginSubmit}>
                <div className="form-group mb-3">
                   <div className="form-group">
                      <div className="form-group mb-3">
                           <label className="mb-1">
                              <strong className="text-black">Username</strong>
                            </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="username"
                           name="username"
                         onChange={handleInput} 
                         value={loginInput.username}
                          />
                       </div>
                      <span>{loginInput.error_list.username}</span>
                     

                        <label className="text-label text-black">Password</label>
                        <div className="input-group transparent-append mb-2">
                          
                            <span className="input-group-text">
                              {" "}
                              <i className="fa fa-lock" />{" "}
                            </span>
                          
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="val-password1"
                            placeholder="password"
                            name="password"
                            onChange={handleInput} 
                            value={loginInput.password}
                          />

                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >

                              {" "}
                  {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                            
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                          </div>


                        </div>
                     <span>{loginInput.error_list.password}</span>



                      <div className="text-center mt-4">
                          <button type="submit" className="btn btn-primary btn-block">
                            Login
                          </button>
                      </div>
                  </div>
                </div>
            </form>
            <div className="new-account mt-3">
          <p><span className="text-black">New account? <Link className="text-black" to="./register">Sign up</Link></span>
          <span style={{ float: 'right' }}><Link className="text-black" to="./forgot-password">Forgot Password ?</Link></span>
          </p>
        </div>
          </Modal.Body>
      </Modal>




  <form onSubmit={loginSubmit}>
    <div className="d-flex align-items-center justify-content-center p-3 text-white">



      <div className="d-flex align-items-center d-none d-lg-block d-xl-block">
        <i onClick={handleShowLogin} className="bi bi-person-fill fs-2 text-warning"></i>
      </div>
      <input
        type="text"
        placeholder="Username"
        className="form-control mx-2 d-none d-lg-block d-xl-block"
        name="username"
        onChange={handleInput} 
        value={loginInput.username}
        style={{ maxWidth: "150px", height: "28px" }}
      />


      <input
        type={`${showPassword ? "text" : "password"}`}
        placeholder="Password"
        id="val-password1"
        name="password"
        onChange={handleInput} 
        value={loginInput.password}
        className="form-control mx-2 d-none d-lg-block d-xl-block"
        style={{ maxWidth: "150px", height: "28px" }}
      />

      <button type="submit" className="btn btn-danger d-none d-lg-block d-xl-block">
        Login <i className="bi bi-box-arrow-in-right"></i>
      </button>

      <Link className="d-lg-none" to="?login=true" >
      <button className="btn btn-danger">
        Login <i className="bi bi-box-arrow-in-right"></i>
      </button>
      </Link>
      
      <Link className="d-none d-lg-block d-xl-block" to="/register" >
      <button className="btn btn-danger">
        signup <i className="bi bi-box-arrow-in-right"></i>
      </button>
      </Link>
    </div>
  </form>













  </ul>
   );
    }
    else
    {
  AuthButtons = (
  <ul className="navbar-nav header-right">
  


    <Dropdown as="li" className="nav-item dropdown header-profile" style={{ marginRight: '0px'}}>
        <button type="button"  onClick={toggleModal} className="me-2 btn btn-dark btn-rounded d-none d-sm-block">
          <span className="fa fa-globe text-white" onClick={fetchProfileData} >
          {' ' + divLang.toUpperCase() + ' , '}
          </span>
          {' ' + divCarr }
        </button>


            <ul className="navbar-nav header-right d-none d-sm-block">
                <div as="li" className="nav-item">     
                 <Link to="/deposit">
                    <button type="button" class="me-2 btn btn-warning">Deposit</button>
                   </Link>
                </div>
            </ul>


        {showModal && (
            <div className="modal show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
              <div className="modal-dialog" role="document">
                <div style={{marginTop: '100px'}} className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Select Country</h5>
                    <button
                      type="button"
                      onClick={toggleModal}
                      aria-label="Close"
                    >
                      <span style={{ padding: '5px', fontSize: '10px' }} aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">

                      <div className="row">

                     <div class="col col-lg-6">
                      <div className="mail-list rounded  mt-4">

                        <Link onClick={() => handleLanClick('en')} className={"list-group-item text-black " + (divLang === 'en'  && "active" )} >
                        <i className="fa fa-language font-18 align-middle me-2 text-black"></i>
                        English
                        </Link>
                        <Link onClick={() => handleLanClick('fr')} className={"list-group-item text-black " + (divLang === 'fr'  && "active" )}>
                        <i className="fa fa-language font-18 align-middle me-2 text-black"></i>
                        Français
                        </Link>
                        <Link onClick={() => handleLanClick('bn')} className={"list-group-item text-black " + (divLang === 'bn'  && "active" )}>
                        <i className="fa fa-language font-18 align-middle me-2 text-black"></i>
                        বাংলা
                        </Link>
                        <Link onClick={() => handleLanClick('hi')} className={"list-group-item text-black " + (divLang === 'hi'  && "active" )}>
                        <i className="fa fa-language font-18 align-middle me-2 text-black"></i>
                        हिंदी
                        </Link>
                      </div>
                      </div>

                     <div class="col col-lg-6 text-black">
                          <div className="mail-list rounded text-black mt-4">

                            <Link onClick={() => handleCarClick('USD')} className={"list-group-item text-black " + (divCarr === 'USD'  && "active" )} >
                            <i className="fa fa-globe font-18 align-middle me-2 text-black"></i>
                            USD
                            </Link>
                            <Link onClick={() => handleCarClick('EUR')} className={"list-group-item text-black " + (divCarr === 'EUR'  && "active" )}>
                            <i className="fa fa-globe font-18 align-middle me-2 text-black"></i>
                            EUR
                            </Link>
                            <Link onClick={() => handleCarClick('BDT')} className={"list-group-item text-black " + (divCarr === 'BDT'  && "active" )}>
                            <i className="fa fa-globe font-18 align-middle me-2 text-black"></i>
                            BDT
                            </Link>
                            <Link onClick={() => handleCarClick('INR')} className={"list-group-item text-black " + (divCarr === 'INR'  && "active" )}>
                            <i className="fa fa-globe font-18 align-middle me-2 text-black"></i>
                            INR
                            </Link>
                        </div>
                      </div>
                   </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={toggleModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

        <button type="button" className="me-2 btn btn-dark btn-rounded with-nav-p">
            <span className="d-none d-sm-block btn-icon-start text-primary" onClick={fetchProfileData} >
            <i className="ti-reload"></i>
            </span> 

          <Link style={{ fontsize: '10px' }} to="/deposit" >
          <span className="d-block d-sm-none">
              <span className="btn-icon-start text-primary" >Deposit</span>       
          </span>
          </Link>  

          {CurrencySymbol}{parseFloat(parseFloat(balance).toFixed(2))} 
        </button>

      <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
       <Link to={"/menu"}>
        <img src={profile_h} width={30} alt="Profile" />
        </Link>
      </Dropdown.Toggle>
    </Dropdown>
  </ul>
        );
    }

const [searchBut, setSearchBut] = useState(false);  
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  return ( 
     <>
    <div style={{ zIndex: 998, paddingLeft: '0px', background: '#1e323f' }} className="header border-bottom">
        <div style={{ paddingLeft: '6px' }} className="header-content des-nav-ziro">

        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
         
      <Link to="/" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
      <Fragment>
      <img style={{ width:"80%", marginLeft: '10px' }} className="form-inline " src={avatar} alt=""/>
      </Fragment>
        ) : (
      <Fragment>
      <img style={{ width:"80%",marginLeft: '10px' }} className="form-inline " src={avatar} alt=""/>
      </Fragment>
        )}
      </Link>

      <div className="nav-item d-flex align-items-center text-black">
        <div className="input-group search-area">
          <input
            type="text"
            className={`form-control text-black ${searchBut ? "active" : ""}`}
            placeholder="Search.."
            style={{ height: "28px" }}
          />
          <span style={{ height: "28px" }} className="input-group-text text-black" onClick={() => setSearchBut(!searchBut)}>
            <Link to={"#"}><i className="text-black flaticon-381-search-2"></i></Link>
          </span>
        </div>
      </div> 


         <ul className="navbar-nav header-right">
           {AuthButtons}
          </ul>
          </div>
        </nav>
        </div>
    </div>



        <nav className="navbar navbar-expand-lg" style={{ backgroundColor: '#ffbf19', marginTop: '-14px', paddingTop: '0px', paddingBottom: '0px',  paddingTop: '70px' }}>
            <div className="container-fluid d-none d-lg-block d-xl-block">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link className="nav-link" to="/" style={{ color: 'black' }}>Home</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/sportsbook" style={{ color: 'black' }}>In-Play</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/home-game/all-game" style={{ color: 'black' }}>Multi Markets</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/sportsbook" style={{ color: 'black' }}>
                          Cricket <span className="badge bg-danger">8</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/sportsbook" style={{ color: 'black' }}>
                          Soccer <span className="badge bg-danger">3</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/sportsbook" style={{ color: 'black' }}>
                          Tennis <span className="badge bg-danger">1</span>
                        </Link>
                      </li>
                    </ul>
                    <div className="ms-auto me-2" style={{ color: 'black' }}>Time Zone: GMT+5:30</div>
                    <button className="btn btn-dark">One Click Bet</button>
                    <span className="ms-2" style={{ cursor: 'pointer' }}>&#9881;</span>
                </div>
            </div>
        </nav>



     </>

  );
};

export default Header;
