import React, { Fragment,useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider'
import {Dropdown, Tab, Nav, Col, Row, Form} from 'react-bootstrap';
import PageTitle from "../../../layouts/PageTitle";
import axios from "axios";
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Checkout = () => {
   const { t } = useTranslation();
   const [withdraw, setWithdraw] = useState([]);
   const [loading, setLoading] = useState(false);
   const [loadingdd, setLoadingd] = useState(false); // Add loading state
   const [selectedTypeOfPlot, setTypeOfPlot] = useState('');
   const { BaseUrl } = GlobalSet();

    useEffect(()=>{
        const data = {
              key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
             }
        axios.post('/apps-withdraw',data)
        .then(response=>{
           setWithdraw(response.data.data)
           console.log(response.data.data)
           setLoadingd(true);
       })
     .catch(error=>{
            
           console.log(error)
       })
     },[])


    const [depositInput, setDepositInput] = useState({
        gateway_id: '',
        amount: '',
        transaction_number: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setDepositInput({...depositInput, [e.target.name]: e.target.value });
    }

    const depositSubmit = (e) => {
        e.preventDefault();
        const auth_token = localStorage.getItem('auth_token');
        const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            gateway_id: selectedTypeOfPlot,
            currency: selectedCurrency,
            amount: depositInput.amount,
            transaction_number: depositInput.transaction_number,
            password: depositInput.password,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
           
        }

      if (auth_token !== null  && !loading) {
          setLoading(true);
          axios.post(`/apps-withdraw-sub`, data).then(res => {
          setLoading(false);
                if(res.data.status === 'success')
                {
                   swal("Success",res.data.message,"success");
                  setDepositInput({...depositInput,
                    gateway_id: '',
                    amount: '',
                    transaction_number: '',
                    password: '',
                    
                  });
                }
                else if(res.data.status === 'fail')
                {
                  swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setDepositInput({...depositInput, error_list: res.data.validation_error});
                }
            setLoading(false);
            });
          }

          }


let projectTypesdd = null;
if (loadingdd) {
    projectTypesdd = (
      <>
          {withdraw.length > 0 && (
              <p className="mb-3 mt-3">Type of Withdraw Method</p>
          )}
          {withdraw.map((getwayitem, index) => (
            <div key={getwayitem.id} className="col-lg-12">
                <div>
                    <div style={{background: "#3e3e3e"}} className="card-header border-0 pt-2 pb-2">
                        <h5 className="text-white"> {t(getwayitem.withdrawal_type)}</h5>
                        <img src={BaseUrl+(getwayitem.photo)} alt="" className="me-3 rounded" width={75} height={30}/>
                    </div> 

                    {getwayitem.allmatch.length === 0 || getwayitem.allmatch === null ? (
                        <div> 
                            <h5 className="text-center pt-2">Not Found</h5>
                            <p className="text-center">Please Contact Agent</p>
                        </div>
                          ) : 
                         null
                      }
                        <div className="row pt-0 pb-0 p-3">
                          {getwayitem.allmatch.map((element)=>(
                              <div className="col-xl-2 col-xxl-3 col-lg-3 col-md-4 col-sm-6 col-6 int-col p-1">   
                                 <label data-kt-button="true">
                                    <div key={element.id}>
                                      <div className="card mb-0">
                                          <div className="img-bx text-center ">
                                              <img src={BaseUrl+(element.photo)} alt="" className="me-3 m-3 card-list-img" width="35%" />
                                              <h5 className="nav-text text-center p-1 rounded-bottom" style={{display: "block", background: "#3e3e3e"}}> {element.Payment_name + " "} 
                                              <input
                                                  className="form-check-input border border-gray-500"
                                                  type="radio"
                                                  name="type_of_plot"
                                                  value={element.id}
                                                  required
                                                  checked={selectedTypeOfPlot === element.id}
                                                  onChange={() => setTypeOfPlot(element.id)}
                                              />
                                          </h5>
                                          </div>  
                                      </div>  
                                    </div>  
                                     </label>
                                </div>  
                                ))}
                           </div>   
                </div>
            </div>
          ))}
      </>
    );
}


   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-12 order-md-1">
                           <h4 className="mb-3">{t('pro_with_withdraw')}</h4>

                              <Row>
                                <Col>
                                  <Nav fill variant="tabs" defaultActiveKey="tab2">
                                    <Nav.Item>
                                      <Nav.Link as={Link} to="/withdraw" eventKey="tab2">
                                        Company 
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link as={Link} to="/ag-withdraw" eventKey="tab1">
                                        Agent
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </Col>
                              </Row>


                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">

                              <div style={{margin: "0px auto"}}>
                                 {projectTypesdd}
                              </div>

                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="lastName">{t('pro_with_amount')}</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="lastName"
                                       placeholder="Enter Amount"
                                       required
                                       name="amount"
                                       onChange={handleInput} 
                                       value={depositInput.amount}
                                    />
                                       <span className="text-danger">{depositInput.error_list.amount}</span>
                                 </div>



                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                       {t('pro_with_tr_number')}
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="firstName"
                                       placeholder="Payment Address or Number"
                                       required
                                       name="transaction_number"
                                       onChange={handleInput} 
                                       value={depositInput.transaction_number}
                                    />
                                    <span className="text-danger">{depositInput.error_list.transaction_number}</span>
                                    
                                 </div>

                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                        {t('pro_with_password')}
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="firstName"
                                       placeholder="Login Password"
                                       required
                                       name="password"
                                       onChange={handleInput} 
                                       value={depositInput.password}
                                    />
                                   <span className="text-danger">{depositInput.error_list.password}</span>
                                    
                                 </div>
                              </div>

                              <hr className="mb-4" />
                              <button
                                 className="btn btn-primary btn-lg btn-block"
                                 type="submit"
                              >
                              {loading ?  t('comon_Submitting') : t('pro_with_withdraw')}
                              </button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
