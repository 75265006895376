import { Card, Col, ListGroup, Row } from "react-bootstrap";
import axios from "axios";
import {Link} from 'react-router-dom';
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { GlobalSet } from '../components/global/GlobalProvider';


const Footer = () => {
  const { t } = useTranslation();
  var d = new Date();

  const { BaseUrl } = GlobalSet();
  var home_menu = BaseUrl + "public/appssource/sports_icons/home_menu.png";
  var casino_menu = BaseUrl + "public/appssource/sports_icons/casino_menu.png";
  var sports_menu = BaseUrl + "public/appssource/sports_icons/sports_menu.png";
  var bonus_menu = BaseUrl + "public/appssource/sports_icons/bonus_menu.png"; 
  var multi_icon = BaseUrl + "public/appssource/sports_icons/multi_icon.png"; 
  var user_icon = BaseUrl + "public/appssource/sports_icons/user_icon.png"; 
  var inplay_icon = BaseUrl + "public/appssource/sports_icons/inplay_icon.png"; 
  var promot = BaseUrl + "public/appssource/sports_icons/promot.svg"; 
   
var AuthButtons = '';
if(!localStorage.getItem('auth_token'))
{
  AuthButtons = (
      <Link className="footer-nav-single" to={"?login=true"}>
          <div className="menu-wrapper">
                <img src={user_icon} alt="Account" width="30px" />
              <span className="nav-text">Account</span>
          </div>
      </Link>
    )
}else{
  AuthButtons = (
      <Link className="footer-nav-single" to={"/menu"}>
          <div className="menu-wrapper">
                <img src={user_icon} alt="Account" width="30px" />
              <span className="nav-text">Account</span>
          </div>
      </Link>
    )
}



  return (
    <div className=" m-4 text-black">
      <div style={{textAlign:'center'}} className="copyright">
      <Row>
        <Col  className="col-xl-6 col-xxl-6 col-sm-6">
            <Card.Header>
              <Card.Title>{t('pro_footer_information')}</Card.Title>
            </Card.Header>
            <Card.Body>

            <Row>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group">
                    <li as="ul">
                        <a href="https://partner.velki.run/" target="_blank" rel="noopener noreferrer">
                          <li className="mb-2 text-black">{t('pro_footer_slot_dic')}</li>
                        </a>
                        <Link to={"/view-page/1/about-us"}>
                         <li className="mb-2 text-black"> {t('pro_footer_about_us')}</li>
                        </Link>
                        <Link to={"/view-page/2/Terms-and-conditions"}>
                         <li className="mb-2 text-black"> {t('pro_footer_trms_con')}</li>
                        </Link>
                        <Link to={"/view-page/3/privacy-policy"}>
                         <li className="mb-2 text-black"> {t('pro_footer_pr_po')}</li>
                        </Link>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <li className="mb-2"> {t('pro_footer_lv_gm')} </li>
                        <li className="mb-2"> {t('pro_footer_foo_bl')} </li>
                        <li className="mb-2"> {t('pro_footer_bask_bl')} </li>
                    </li>
                  </div>
              </Col>
            </Row>
            </Card.Body>
        </Col>
        <Col  className="col-xl-6 col-xxl-6 col-sm-6">
            <Card.Header>
              <Card.Title>{t('pro_footer_sports')} </Card.Title>
            </Card.Header>
            <Card.Body>


            <Row>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <li className="mb-2"> {t('pro_footer_casino')}  </li>
                        <li className="mb-2"> {t('pro_footer_lv_cas')} </li>
                        <li className="mb-2"> {t('pro_footer_tv_gm')} </li>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <li className="mb-2"> {t('pro_footer_bt_res')} </li>
                        <li className="mb-2">  {t('pro_footer_bt_info')} </li>
                        <li className="mb-2">{t('pro_footer_res_gm')} </li>
                    </li>
                  </div>
              </Col>
            </Row>


            </Card.Body>
        </Col>
      </Row>

        <p>
          {t('pro_footer_copyright')}
         {/* {d.getFullYear()}*/}
        </p>
      </div>



        <footer className="mobilefutshow">
            <div className="footer-nav-wrapper">


                <Link className="footer-nav-single" to={"/sportsbook"}>
                    <div className="menu-wrapper">
                        <img src={promot} alt="Home" width="30px" />
                        <span className="nav-text">Sports</span>
                    </div>
                </Link>
                <Link className="footer-nav-single" to={"/sportsbook"}>
                    <div className="menu-wrapper">
                        <img src={sports_menu} alt="Sports" width="30px" />
                        <span className="nav-text">Sports</span>
                    </div>
                </Link>
                <Link className="footer-nav-single" to={"/"}>
                    <div className="menu-wrapper">
                        <img src={home_menu} alt="Home" width="30px" />
                        <span className="nav-text">Home</span>
                    </div>
                </Link>
                <Link className="footer-nav-single" to={"/home-game/all-game"}>
                    <div className="menu-wrapper">
                        <img src={casino_menu} alt="Casino" width="30px" />
                        <span className="nav-text">Casino</span>
                    </div>
                </Link>

                {AuthButtons}

            </div>
        </footer>

      
    </div>
  );
};

export default Footer;
