import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import loading_img from "../../../images/profile/loading.gif";
import { GlobalSet } from '../global/GlobalProvider';
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
const Home = () => {
    const { changeBackground } = useContext(ThemeContext);
    useEffect(() => {
        changeBackground({ value: "light", label: "Light" });
    }, []);

   const { BaseUrl } = GlobalSet();
   const [salider, setSalider] = useState([]); 
   const [homeCasino, setCasino] = useState([]);
   const [homeTopSlider, TopSlider] = useState([]);
   const [homeitem, setHomeItem] = useState([]);   
   const { t } = useTranslation();


  var banner_sports = BaseUrl + "public/appssource/all_slider/banner_sports.jpg";
  var banner_virtualsports = BaseUrl + "public/appssource/all_slider/banner_virtualsports.jpg";
  var banner_skyexchangeBlog = BaseUrl + "public/appssource/all_slider/banner_skyexchangeBlog.jpg";
  var banner_kabaddi = BaseUrl + "public/appssource/all_slider/banner_kabaddi.jpg";



/*.....product code........*/
  const [loading, setLoading] = useState(true);
  const data = {
       key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       }
  const APiResponse = () => {
      axios.post('/home-data',data)
      .then(response=>{
         setSalider(response.data.slider)
         setCasino(response.data.home_casino)
         TopSlider(response.data.top_slider)
         setHomeItem(response.data.home_items)
         setLoading(false); 
       })
   .catch(error=>{
         console.log(error)
     });
   }
    useEffect(()=>{
   const interval = setInterval(
      (function callApi() {
        APiResponse();
        return callApi;
      })(),
      70000000
    );
    return () => clearInterval(interval);
    },[]);


    
//mobile odds option
  const casino_game_s = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 100,
    slidesToShow: 10,
    slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1560,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 5,
                },
            },  
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 4,
                },
            },
        ],
  };
  const casino_home_s = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 100,
    slidesToShow: 10,
    slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1560,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 5,
                },
            },  
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 4,
                },
            },
        ],
  };


return(
     <>

      <span className="d-xl-block">
         <div className="row">
            <Fragment>
                <Carousel className="p-0">
                  {salider.map((carousel, i) => (
                    <Carousel.Item key={i}>
                      <img
                        src={BaseUrl+(carousel.img_link)}
                        className='d-block w-100'
                        alt={`Slide ${i + 1}`}
                        style={{ borderRadius: '10px' }}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel> 


            </Fragment> 
                  </div>
      
        <div className="row">
            <Fragment>
              <Col xl="12" className="pl-1 mt-2" >
                <div className="row">


                    <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-12 col-sm-12 col-12 int-col p-1"> 
                          <div>
                            <Link to="" className="position-relative d-block games_main">

                                <div className="img-bx">
                                    <img src={banner_sports} alt="" className=" me-3 card-list-img w-100" width="130" />
                                </div> 
                                <div class="title">
                                    <h2 className="mb-1">Supernowa</h2>
                                    <span>Play Now</span>
                                </div> 
                             </Link>
                          </div>  
                      </div> 

                    <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-12 col-sm-12 col-12 int-col p-1"> 
                          <div>
                            <Link to="" className="position-relative d-block games_main">

                                <div className="img-bx">
                                    <img src={banner_virtualsports} alt="" className=" me-3 card-list-img w-100" width="130" />
                                </div>  
                                <div class="title">
                                    <h2 className="mb-1">Supernowa</h2>
                                    <span>Play Now</span>
                                </div>
                             </Link>
                          </div>  
                      </div> 

                    <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-12 col-sm-12 col-12 int-col p-1"> 
                          <div>
                            <Link to="" className="position-relative d-block games_main">

                                <div className="img-bx">
                                    <img src={banner_skyexchangeBlog} alt="" className=" me-3 card-list-img w-100" width="130" />
                                </div>  
                                <div class="title">
                                    <h2 className="mb-1">Supernowa</h2>
                                    <span>Play Now</span>
                                </div>
                             </Link>
                          </div>  
                      </div> 

                    <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-12 col-sm-12 col-12 int-col p-1">  
                          <div>
                            <Link to="" className="position-relative d-block games_main">

                                <div className="img-bx">
                                    <img src={banner_kabaddi} alt="" className=" me-3 card-list-img w-100" width="130" />
                                </div>  
                                <div class="title">
                                    <h2 className="mb-1">Supernowa</h2>
                                    <span>Play Now</span>
                                </div>
                             </Link>
                          </div>  
                      </div> 

            
                </div>
              </Col>
          </Fragment>
        </div> 

        <div className="row">
            <Fragment>
              <Col xl="12" className="pl-1 mt-2" >
                <div className="row">
                 {homeitem.map((ele)=>(
                    <div  className="col-xl-3 col-xxl-3 col-lg-3 col-md-6 col-sm-6 col-6 int-col p-1">  
                          <div key={ele.id}>
                            <Link to={ele.link} className="position-relative d-block games_main">

                                <div className="img-bx">
                                    <img src={BaseUrl+(ele.img_link)} alt="" className=" me-3 card-list-img w-100" width="130" />
                                        <div class="title">
                                            <h2 className="mb-1">{ ele.title }</h2>
                                            <span>Play Now</span>
                                        </div>
                                </div>  
                             </Link>
                          </div>  
                      </div> 
                  ))}
                </div>
              </Col>
          </Fragment>
        </div> 


      </span>
    </>
  )
}
export default Home;