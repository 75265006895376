import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { ButtonGroup, Button, Col, Modal } from 'react-bootstrap';
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Home = () => {
  const [onHomeGame, setOnHomeGame] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const videoRef = useRef(null);
  const { id } = useParams();
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  const { t } = useTranslation();
  const handleBack = (e) => {
    e.preventDefault();
    window.history.back(); // Goes back to the previous page
  };
  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const { data } = await axios.post('/get-one-game', {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          id: id,
          currency: selectedCurrency,
        });
        setOnHomeGame(data.game_link);
        if(data.status === 'success') {
          console.log("face game");
        } else if(data.status === 'fail') {
          swal("Warning", data.message, "warning");
        } else {
          swal("Warning", "Something went wrong", "warning");
        }
      } catch (error) {
        console.error("Error fetching game data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameData();
  }, [id]);

  const handleToggleFullScreen = () => {
    const video = videoRef.current;
    if (video) {
      if (video.requestFullscreen) video.requestFullscreen();
      else if (video.mozRequestFullScreen) video.mozRequestFullScreen();
      else if (video.webkitRequestFullscreen) video.webkitRequestFullscreen();
      else if (video.msRequestFullscreen) video.msRequestFullscreen();
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      fullscreen
      centered
    >
      <Modal.Body style={{ padding: '0px' }}>


               <div className="col-xl-12" style={{ width : '98%'}}>
                 <div className="row">
                   <div className="col-xl-9 col-lg-9 col-xxl-9 col-md-9 col-9">
                     <div className="table-responsive">
                        <table
                          className="display w-100 dataTable "
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                           style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}
                        >
                          <tbody>
                            <tr className="odd" role="row">
                              <td> <Link  onClick={handleBack} className="text-black"><span className="fa fa-arrow-circle-left text-black"></span></Link></td>
                              <td> <Link to={"/home-game/slots"} className="text-black">{t('All Game')}</Link></td>
                              <td> <Link to={"/home-game/live-casino"} className="text-black">{t('Live Casino')}</Link></td>
                              <td> <Link to={"/home-game/slots"} className="text-black">{t('Table Games')}</Link></td>
                              <td> <Link to={"/home-game/jackpot-slots"} className="text-black">{t('Slots')}</Link></td>
                              <td> <Link to={"/home-game/poker"} className="text-black">{t('Poker')}</Link></td>
                              <td> <Link to={"/home-game/jackpot-slots"} className="text-black">{t('Table Games')}</Link></td>
                              <td> <Link to={"/home-game/slots"} className="text-black">{t('Provider')}</Link></td>
                              <td> <Link to={"/home-game/all-game"} className="text-black">{t('Popular')}</Link></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-xxl-3 col-md-3 col-3">
                     <div className="table-responsive">
                        <table
                          className="display w-100 dataTable "
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                           style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}
                        >
                          <tbody>
                            <tr className="odd" role="row">
                              <td style={{ marginRight: "5px" }}> <Link  onClick={handleBack} className="text-black"><span className="fa fa-heart text-black"></span></Link></td>
                              <td> <Link onClick={handleToggleFullScreen} className="text-black"><span className="fa fa-expand text-black"></span></Link></td>
                             
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

        <div className="card p-0 mb-0" style={{ width: '100%', height: '100%', position: 'relative' }}>
       









          {loading ? (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
              <span className="fa fa-spinner fa-spin text-black" style={{ fontSize: "50px" }}></span>
            </div>
          ) : (
            <iframe
              ref={videoRef}
              className="rounded"
              title="Game"
              src={onHomeGame}
              frameBorder="0"
              style={{ width: '100%', height: '100%', zIndex: 2 }}
              allowFullScreen
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Home;
