import React, { Fragment, useState, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { Link ,useHistory} from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import axios from "axios";
import swal from "sweetalert";
import { useEffect } from 'react';
//** Import Image */
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessage':
			return { ...state, sendMessage: !state.sendMessage }		
		case 'postModal':
			return { ...state, post: !state.post }
		case 'linkModal':
			return { ...state, link: !state.link }		
		case 'cameraModal':
			return { ...state, camera: !state.camera }		
		case 'replyModal':
			return { ...state, reply: !state.reply }
		default:
			return state	
	}	
}

  const AppProfile = () => {
  const auth_token = localStorage.getItem('auth_token');
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  
	const deposit_icon = "public/appssource/user_icon/total_deposit.png";
	const withdrawal_icon = "public/appssource/user_icon/withdrawal.png";
	const income_icon = "public/appssource/user_icon/total_income.png";
	const balance_icon = "public/appssource/user_icon/current_balance.png";

  if (auth_token !== null) {
    const params = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9', // Add your key here
      currency: selectedCurrency, // Add the currency parameter
    };

    axios.get('/home-data', { params })
      .then(response => {
          console.log("done.");
         })
      .catch(error => {
        console.log(error);
      });
  }


  const { CurrencySymbol } = GlobalSet();
  const { BaseUrl } = GlobalSet();
  const { t } = useTranslation();
  const [user, setUser] = useState([]);
    useEffect(()=>{
    	const auth_token = localStorage.getItem('auth_token');
    	const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
    	if (auth_token !== null) {
    		 axios.post('/apps-profile',data)
       	 .then(response=>{
           setUser(response.data)
           console.log(response.data)
           
			     })
			     .catch(error=>{
			       console.log(error)
			   })
    	}

    },[])

	let picture_Url;
	let Email;
	let Username;
	let mobile_No;
	let name;
	let balance;
	let bonus_balance;
	let total_deposit;
	let total_Income;
	let total_withdraw;
	let affiliate_earning;
	if (user == "") {
		console.log(user);
	}else{

		Email=user.data.email;
		if(user.data.picture_Url === '0'){
			picture_Url = profile;
		}else{
			picture_Url = BaseUrl+user.data.picture_Url;
		}
		name=user.data.name;
		mobile_No=user.data.mobile_No;
		Username=user.data.username;
		balance=user.data.balance;
		bonus_balance=user.data.bonus_balance;
		total_deposit=user.data.total_deposit;
		total_Income=user.data.total_Income;
		total_withdraw=user.data.total_withdraw;
		affiliate_earning=user.data.affiliate_earning;

	}
		
	const options = {
	  settings: {
	    overlayColor: "#000000",
	  },
	};

	const [state, dispatch] = useReducer(reducer, initialState);
	const [SetYouImg, AYouImage] = useState(null)
	const [ggs_photo, ApiYouImage] = useState([]);

  const [registerInput, setRegister] = useState({
    your_image:'',
      error_list: [],
  });

	const YourImage = (event) => {
	 if (event.target.files && event.target.files[0]) {
	   AYouImage(URL.createObjectURL(event.target.files[0]));
	   ApiYouImage({your_image: event.target.files[0] });
	   setShowText(true);
	 }
	}

	const [errorlist, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showText, setShowText] = useState(false);
	//form submit handler
  const submitProduct=(e) =>{
  	if(!loading){
 	  setLoading(true);
      e.preventDefault();
      const formData= new FormData();
      formData.append('key_s', "eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9");
      formData.append('your_image', ggs_photo.your_image);
      axios.post('/change-profile-photo', formData)
      .then((res)=>{
  	  setLoading(false);
            if(res.data.status === 'success')
            {
                swal("Success",res.data.message,"success");
            }
           else if(res.data.status === 'fail')
            {
                swal("Warning",res.data.message,"warning");
            }
            else
            {
                setRegister({...registerInput, error_list: res.data.validation_error});
            }
     		setLoading(false);
        setShowText(false);
      });
	}
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">



						<form onSubmit={submitProduct} enctype= "multipart/form-data">
                <div className="profile-photo">
				        <i className="fa fa-edit" style={{ cursor: "pointer" }}>
				            <input 
				                type="file" 
				                id="formFile" 
				                name="your_image"
				                onChange={YourImage}
				                style={{
				                    position: "absolute",
				                    opacity: 0,
				                    width: "100%",
				                    height: "100%",
				                    left: 0,
				                    top: 0,
				                    cursor: "pointer"
				                }}
				            />
				        </i>

                  <img
                    src={SetYouImg || picture_Url || profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
            {showText && (
						<button type="submit" className="btn btn-primary sw-btn-next ms-1 mt-1">{loading ? t('comon_Submitting') : t('comon_Submit')}</button>
            )}	                
            </form>



                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">{Username}</h4>
                    <p>{t('pro_pg_username')}</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



						<div className="col-xl-12">
							<div className="row">
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span style={{ background: 'none'}}>
												<img
                          src={BaseUrl + balance_icon}
                          alt=""
                       		width="18px"
                          className="me-3 card-list-img w-100"
                        />
												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(balance).toFixed(4))}</h4>
													<p className="mb-0 text-nowrap">{t('pro_pg_curr_bal')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
{/*								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>

													<svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 24 24" fill="none" stroke="#e50505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
																										
												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(bonus_balance).toFixed(4))}</h4>
													<p className="mb-0 text-nowrap">{t('pro_pg_bon_inc')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>*/}
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span style={{ background: 'none'}}>
												<img
                          src={BaseUrl + deposit_icon}
                          alt=""
                       		width="18px"
                          className="me-3 card-list-img w-100"
                        />
												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_deposit).toFixed(4))}</h4>
													<p className="mb-0 text-nowrap ">{t('pro_pg_tot_dep')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span style={{ background: 'none'}}>
												<img
                          src={BaseUrl + income_icon}
                          alt=""
                       		width="18px"
                          className="me-3 card-list-img w-100"
                        />
												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_Income).toFixed(4))}</h4>
													<p className="mb-0">{t('pro_pg_tot_inc')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span style={{ background: 'none'}}>
												<img
                          src={BaseUrl + withdrawal_icon}
                          alt=""
                       		width="18px"
                          className="me-3 card-list-img w-100"
                        />
												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_withdraw).toFixed(4))}</h4>
													<p className="mb-0">{t('pro_pg_tot_wit')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



     <div className="row">
        <div className="col-xl-4">

		</div>	
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
					<Tab.Container defaultActiveKey='About'>					
						<Nav as='ul' className="nav nav-tabs mb-4">
							<Nav.Item as='li'i className="nav-item">
								<Nav.Link to="#about-me"  eventKey='About'>{t('pro_pg_ab_me')}</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>

							<Tab.Pane id="about-me" eventKey='About'>
								<div className="profile-personal-info">
									<h4 className="text-primary mb-4">
										{t('pro_pg_info')}
									</h4>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> {t('pro_pg_name')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{name}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> {t('pro_pg_username')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{Username}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_mob_num')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{mobile_No}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_email')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{Email}</span>
										</div>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>	
					</Tab.Container>		
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </Fragment>
  );
};

export default AppProfile;
